<template>
  <div class="PuzzleList_I">
    <div class="headNavs">
      <div class="headTilte">拼团列表</div>
      <div class="headDetails">说明：包含商品名称、团长、人数、时间、状态、详情</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>虚拟开团</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入商品名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="id" label="拼团ID" width="100" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="商品名称"  align="center">
          <template #default="scope">
              <span>{{ scope.row.goods ? scope.row.goods.goods_name : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="团长"  align="center">
          <template #default="scope">
              <span>{{ scope.row.user ? scope.row.user.nickname : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="need_num" label="团所需人数"  align="center"></el-table-column>
        <el-table-column prop="now_num" label="团当前人数" align="center"></el-table-column>
        <el-table-column prop="starttime_text" label="团开始时间"  align="center"></el-table-column>
        <el-table-column prop="endtime_text" label="团结束时间" width="200" align="center"></el-table-column>
        <el-table-column prop="status_text" label="拼团状态"  align="center"></el-table-column>
        <el-table-column label="操作" align="center"  >
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.row)"
              >拼团详情   
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div><div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>虚拟开团</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入商品名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="id" label="拼团ID" width="100" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="商品名称"  align="center">
          <template #default="scope">
              <span>{{ scope.row.goods ? scope.row.goods.goods_name : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="团长"  align="center">
          <template #default="scope">
              <span>{{ scope.row.user ? scope.row.user.nickname : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="need_num" label="团所需人数"  align="center"></el-table-column>
        <el-table-column prop="now_num" label="团当前人数" align="center"></el-table-column>
        <el-table-column prop="starttime_text" label="团开始时间"  align="center"></el-table-column>
        <el-table-column prop="endtime_text" label="团结束时间" width="200" align="center"></el-table-column>
        <el-table-column prop="status_text" label="拼团状态"  align="center"></el-table-column>
        <el-table-column label="操作" align="center"  >
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.row)"
              >拼团详情   
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

  <div class="show"  v-if="addPanel || editPanel">
    <div class="rightPanel-background"/>
    <div class="EditNew" style="width:760px">
      <div class="EditNewTop">
        <div>{{titleMath}}</div>
        <div @click="Close()"><i class="el-icon-close"></i></div>
      </div>
      <el-form class="EditNewForm" v-if="sku == 1">
        <el-form-item class="EditNewFormItem">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>买家昵称</div>
          <div class="EditNewFormItemIpt">
            <el-input v-model="userGoods.virtualUser.nickname" disabled class="El_ii"></el-input>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormItem">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>团开始时间</div>
          <div class="EditNewFormItemIpt">
            <el-date-picker v-model="Row.starttime" style="width:100%" type="datetime" placeholder="请选择开始时间" :disabledDate="disabledDate"/>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormItem">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>商品名称</div>
          <div class="EditNewFormItemIpt">
            <el-select v-model="Row.goods_id" placeholder="请选择商品名称">
              <el-option v-for="item in userGoods.goodsList" :key="item.goods_id" :label="item.goods_short_name" :value="item.goods_id"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormItem">
          <div class="EditNewFormItemName"><span class="signSpan">* </span>服务商</div>
          <div class="EditNewFormItemIpt">
            <el-select v-model="Row.business_id" placeholder="请选择服务商">
              <el-option v-for="item in userGoods.businessList" :key="item.id" :label="item.username" :value="item.id"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </el-form-item>
      </el-form>
      <div v-if="sku == 2">
        <div class="EditNewForm">
          <div class="invoice">
            <div class="invoiceLi">
              <div>商品名称</div>
              <div>{{goods_name}}</div>
            </div>
            <div class="invoiceLi">
              <div>拼团状态</div>
              <div>{{tuanInfo.status_text}}</div>
            </div>
            <div class="invoiceLi">
              <div>团开始时间</div>
              <div>{{tuanInfo.starttime_text}}</div>
            </div>
            <div class="invoiceLi">
              <div>团结束时间</div>
              <div>{{tuanInfo.endtime_text}}</div>
            </div>
            <div class="invoiceLi">
              <div>团所需人数</div>
              <div>{{tuanInfo.need_num}}</div>
            </div>
            <div class="invoiceLi">
              <div>团当前人数</div>
              <div>{{tuanInfo.need_num}}</div>
            </div>
          </div>
        </div>
        <div style="padding: 0 24px;padding-right: 40px;">
          <el-table
            :data="orderList"
            :span-method="arraySpanMethod"
            border>
            <el-table-column prop="is_sponsor" label="用户身份" align="center">
              <template #default="scope">
                <span>{{ {0:'团员',1:'团长'}[scope.row.is_sponsor||0] }} <span v-if=" is_virtual = 1">(虚拟)</span></span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="用户头像" align="center">
              <template #default="scope">
                <el-image class="Toper"
                  :src="scope.row.business.avatar" alt=""
                  :preview-src-list="[scope.row.business.avatar]"
                  :initial-index="0"
                  :hide-on-click-modal = true
                  fit="cover">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="用户昵称" align="center">
              <template #default="scope">
                <span>{{ scope.row.user ? scope.row.user.nickname : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="business" label="服务商" align="center">
              <template #default="scope">
                <span>{{ scope.row.business ? scope.row.business.username : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createtime" label="参团时间" align="center" />
          </el-table>
        </div>
        
        <div class="ssss">
          <div class="NavLiQuery" @click="tuanJoinAdd()">添加虚拟人数</div>
        </div>

      </div>
    </div>
  </div>


  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      userGoods:[],  //拼团选项列表
      orderList:[],  //用户列表
      tuanInfo:{},  //  详情
      fileList:[],
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      Row: { 
        user_id: "",
        goods_id: "",
        business_id: "",
        starttime: "",
      },
      //搜索参数
      search: {
        keyword: "",
      },
      tuanJoin:{
        id:''
      },
      addPanel: false,
      editPanel: false,
      dialogVisible:false,  // 弹出层控制
      editId: 0,  //删除id 
      titleMath:'',
      total: 0,
      sku : 0 ,
      goods_name:'',
      is_sponsor:'',
      is_virtual:''
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    axios.get('/admin/order/pintuan/userGoods',{   // 服务商
      }).then(function (res) {
        that.userGoods = res.data.data
    })
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/pintuan/tuanList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) { 
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        console.log(that.tableData)
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search.keyword = ''
      }
      that.loadTable();
    },
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '虚拟开团'
      that.sku = 1
      that.Row = {
        user_id: parseInt(that.userGoods.virtualUser.id),
        goods_id: "",
        business_id:"",
        starttime: "",
      };
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/order/pintuan/tuanOpen',
          data: {
            row: that.Row,
          },
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    // 关闭
    Close() {
      var that = this
      that.editPanel = false;
      that.addPanel = false;
    },
    tuanJoinClose(){
      this.dialogVisible = false
      this.tuanJoin.id= ''
    },
    //拼团详情
    HandleEdit(data){
      var that = this
      that.editPanel = true;
      that.titleMath = '拼团详情'
      that.editId = data.id
      that.sku = 2
      axios.get('/admin/order/pintuan/tuanInfo', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        that.tuanInfo = res.data.data.row
        that.goods_name = res.data.data.row.goods.goods_name 
        that.orderList = res.data.data.orderList
      })
    },
    tuanJoinAdd(){
      var that = this
      that.$confirm("确认添加虚拟人数？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'post',
          url: '/admin/order/pintuan/tuanJoin',
          data: {
            id: that.editId,
          },
         }).then(function (res) {   
          that.dialogVisible = false
          that.tuanJoin.id = ''
          console.log(res.data.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            axios.get('/admin/order/pintuan/tuanInfo', {   // 详情
              params: {
                id: that.editId
              }
            }).then(function (res) {
              that.tuanInfo = res.data.data.row
              that.goods_name = res.data.data.row.goods.goods_name 
              that.orderList = res.data.data.orderList
            })
            that.$message.success(res.data.msg);
          }
        })
      })
    },
    //勾选
    handleSelect(e) {
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
    disabledDate(time){
      return time.valueOf() >= new Date()
    }
  }
}
</script>


<style scoped>  
.ssss{
  width: 140px;
  margin: 24px;
}
</style>
